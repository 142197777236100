<template>
    <header class="sticky bg-gray-300 z-50 shadow-md">
        <nav
            class="mx-auto flex max-w-7xl items-center justify-between p-4 lg:px-8"
            aria-label="Global"
        >
            <div class="flex lg:flex-1">
                <a href="/" class="-m-1.5 p-1.5">
                    <span class="sr-only">Band Instrument Repair Academy</span>
                    <img
                        class="h-14 w-auto"
                        src="../../../public/fulllogo_transparent_nobuffer.webp"
                        alt=""
                    />
                </a>
            </div>
            <div class="flex lg:hidden">
                <button
                    type="button"
                    class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                    @click="mobileMenuOpen = true"
                >
                    <span class="sr-only">Open main menu</span>
                    <Bars3Icon class="h-6 w-6" aria-hidden="true" />
                </button>
            </div>
            <PopoverGroup class="hidden lg:flex lg:gap-x-12">
                <!--                    <Popover class="relative">-->
                <!--                        <PopoverButton class="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">-->
                <!--                            Company-->
                <!--                            <ChevronDownIcon class="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />-->
                <!--                        </PopoverButton>-->

                <!--                        <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">-->
                <!--                            <PopoverPanel class="absolute -left-8 top-full z-10 mt-3 w-56 rounded-xl bg-white p-2 shadow-lg ring-1 ring-gray-900/5">-->
                <!--                                <a v-for="item in company" :key="item.name" :href="item.href" class="block rounded-lg px-3 py-2 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50">{{ item.name }}</a>-->
                <!--                            </PopoverPanel>-->
                <!--                        </transition>-->
                <!--                    </Popover>-->

                <a
                    href="/about"
                    class="text-sm font-semibold leading-6 text-gray-900"
                    >About</a
                >
                <a
                    href="/bios"
                    class="text-sm font-semibold leading-6 text-gray-900"
                    >Bios</a
                >

                <!--                    <Popover class="relative">-->
                <!--                        <PopoverButton class="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">-->
                <!--                            Training-->
                <!--                            <ChevronDownIcon class="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />-->
                <!--                        </PopoverButton>-->

                <!--                        <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">-->
                <!--                            <PopoverPanel class="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">-->
                <!--                                <div class="p-4">-->
                <!--                                    <div v-for="item in training" :key="item.name" class="group relative flex gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50">-->
                <!--                                        <div class="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">-->
                <!--                                            <component :is="item.icon" class="h-6 w-6 text-gray-600 group-hover:text-red-700" aria-hidden="true" />-->
                <!--                                        </div>-->
                <!--                                        <div class="flex-auto">-->
                <!--                                            <a :href="item.href" class="block font-semibold text-gray-900">-->
                <!--                                                {{ item.name }}-->
                <!--                                                <span class="absolute inset-0" />-->
                <!--                                            </a>-->
                <!--                                            <p class="mt-1 text-gray-600">{{ item.description }}</p>-->
                <!--                                        </div>-->
                <!--                                    </div>-->
                <!--                                </div>-->
                <!--                                <div class="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">-->
                <!--                                    <a v-for="item in callsToAction" :key="item.name" :href="item.href" class="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100">-->
                <!--                                        <component :is="item.icon" class="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />-->
                <!--                                        {{ item.name }}-->
                <!--                                    </a>-->
                <!--                                </div>-->
                <!--                            </PopoverPanel>-->
                <!--                        </transition>-->
                <!--                    </Popover>-->

                <!--                    <Popover class="relative">-->
                <!--                        <PopoverButton class="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">-->
                <!--                            Instrument Repair-->
                <!--                            <ChevronDownIcon class="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />-->
                <!--                        </PopoverButton>-->

                <!--                        <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">-->
                <!--                            <PopoverPanel class="absolute -left-8 top-full z-10 mt-3 w-56 rounded-xl bg-white p-2 shadow-lg ring-1 ring-gray-900/5">-->
                <!--                                <a v-for="item in repair" :key="item.name" :href="item.href" class="block rounded-lg px-3 py-2 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50">{{ item.name }}</a>-->
                <!--                            </PopoverPanel>-->
                <!--                        </transition>-->
                <!--                    </Popover>-->

                <!--                    <a href="#" class="text-sm font-semibold leading-6 text-gray-900">Clinician Services</a>-->
                <a
                    href="/contact"
                    class="text-sm font-semibold leading-6 text-gray-900"
                    >Contact</a
                >
            </PopoverGroup>
            <div class="hidden lg:flex lg:flex-1 lg:justify-end">
                <!--                    <a href="/dashboard" class="text-sm font-semibold leading-6 text-gray-900">Log in <span aria-hidden="true">&rarr;</span></a>-->
            </div>
        </nav>
        <Dialog
            class="lg:hidden"
            :open="mobileMenuOpen"
            @close="mobileMenuOpen = false"
        >
            <div class="fixed inset-0 z-10" />
            <DialogPanel
                class="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10"
            >
                <div class="flex items-center justify-between">
                    <a href="#" class="-m-1.5 p-1.5">
                        <span class="sr-only"
                            >Band Instrument Repair Academy</span
                        >
                        <img
                            class="h-8 w-auto"
                            src="../../../public/fulllogo_transparent_nobuffer.webp"
                            alt=""
                        />
                    </a>
                    <button
                        type="button"
                        class="-m-2.5 rounded-md p-2.5 text-gray-700"
                        @click="mobileMenuOpen = false"
                    >
                        <span class="sr-only">Close menu</span>
                        <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <div class="mt-6 flow-root">
                    <div class="-my-6 divide-y divide-gray-500/10">
                        <div class="space-y-2 py-6">
                            <Disclosure
                                v-slot="{ open }"
                                as="div"
                                class="-mx-3"
                            >
                                <DisclosureButton
                                    class="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                >
                                    Company
                                    <ChevronDownIcon
                                        :class="[
                                            open ? 'rotate-180' : '',
                                            'h-5 w-5 flex-none',
                                        ]"
                                        aria-hidden="true"
                                    />
                                </DisclosureButton>
                                <DisclosurePanel class="mt-2 space-y-2">
                                    <DisclosureButton
                                        v-for="item in company"
                                        :key="item.name"
                                        as="a"
                                        :href="item.href"
                                        class="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                    >
                                        {{ item.name }}
                                    </DisclosureButton>
                                </DisclosurePanel>
                            </Disclosure>

                            <!--                                <Disclosure as="div" class="-mx-3" v-slot="{ open }">-->
                            <!--                                    <DisclosureButton class="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">-->
                            <!--                                        Training-->
                            <!--                                        <ChevronDownIcon :class="[open ? 'rotate-180' : '', 'h-5 w-5 flex-none']" aria-hidden="true" />-->
                            <!--                                    </DisclosureButton>-->
                            <!--                                    <DisclosurePanel class="mt-2 space-y-2">-->
                            <!--                                        <DisclosureButton v-for="item in [...training, ...callsToAction]" :key="item.name" as="a" :href="item.href" class="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50">{{ item.name }}</DisclosureButton>-->
                            <!--                                    </DisclosurePanel>-->
                            <!--                                </Disclosure>-->

                            <!--                                <Disclosure as="div" class="-mx-3" v-slot="{ open }">-->
                            <!--                                    <DisclosureButton class="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">-->
                            <!--                                        Instrument Repair-->
                            <!--                                        <ChevronDownIcon :class="[open ? 'rotate-180' : '', 'h-5 w-5 flex-none']" aria-hidden="true" />-->
                            <!--                                    </DisclosureButton>-->
                            <!--                                    <DisclosurePanel class="mt-2 space-y-2">-->
                            <!--                                        <DisclosureButton v-for="item in repair" :key="item.name" as="a" :href="item.href" class="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50">{{ item.name }}</DisclosureButton>-->
                            <!--                                    </DisclosurePanel>-->
                            <!--                                </Disclosure>-->

                            <!--                                <a href="#" class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Clinician Services</a>-->
                            <a
                                href="/contact"
                                class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                >Contact</a
                            >
                        </div>
                        <div class="py-6">
                            <!--                                <a href="/dashboard" class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Log in</a>-->
                        </div>
                    </div>
                </div>
            </DialogPanel>
        </Dialog>
    </header>
</template>

<script setup>
import { ref } from "vue";
import {
    Dialog,
    DialogPanel,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
} from "@headlessui/vue";
import {
    AcademicCapIcon,
    ArrowPathIcon,
    Bars3Icon,
    ChartPieIcon,
    ComputerDesktopIcon,
    CurrencyDollarIcon,
    CursorArrowRaysIcon,
    FingerPrintIcon,
    SquaresPlusIcon,
    XMarkIcon,
} from "@heroicons/vue/24/outline";
import {
    ChevronDownIcon,
    PhoneIcon,
    PlayCircleIcon,
} from "@heroicons/vue/20/solid";

const company = [
    { name: "About", href: "/about" },
    { name: "Bios", href: "/bios" },
];
const training = [
    {
        name: "In House",
        description: "Train in person",
        href: "#",
        icon: AcademicCapIcon,
    },
    {
        name: "Virtual",
        description: "Freshen your skills from home",
        href: "#",
        icon: ComputerDesktopIcon,
    },
    {
        name: "Fees",
        description: "Tuition Information",
        href: "#",
        icon: CurrencyDollarIcon,
    },
];
const repair = [
    {
        name: "Woodwind",
        description: "Get a better understanding of your traffic",
        href: "#",
        icon: ChartPieIcon,
    },
    {
        name: "Brass",
        description: "Speak directly to your customers",
        href: "#",
        icon: CursorArrowRaysIcon,
    },
    {
        name: "Pricing",
        description: "Speak directly to your customers",
        href: "#",
        icon: CursorArrowRaysIcon,
    },
];
const callsToAction = [
    { name: "Watch demo", href: "#", icon: PlayCircleIcon },
    { name: "Contact sales", href: "#", icon: PhoneIcon },
];

const mobileMenuOpen = ref(false);
</script>
